import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import styles from './footer.module.scss';

import grainFtrRef from './images/footer-pattern-grain.png';
import callToRef from './images/icon-call-to.svg';
import envelopeRef from './images/icon-envelope.svg';
import bestCityInTheWorldRef from './images/icon-kyiv.svg';

const illynskaStreetMap = `https://www.google.com/maps/place/Illinska+St,+8,+Kyiv,+Ukraine,
+02000/@50.4643784,30.5192751,17z/data=!3m1!4b1!4m6!3m5!1s0x40d4ce408e4dcfd7:0x91c7bdb3932e9948!8m2!3d50.464375!4d30.52185!16s%2Fg%2F11c1ylghg0?entry=ttu`;

/* eslint-disable */
// @ts-ignore
function Footer({ t }) {
  return (
    <div className={classNames(styles.ftr, 'container')} id="Contacts">
      <div className={styles.ftrCol}>
        <div className={styles.ftrTitle}>{t('Contact us')}</div>
        <div className={styles.ftrDescription}>{t('let’s create smthg great together')}</div>
        <img src={grainFtrRef} alt="Ukrainian Grain" style={{ maxWidth: 134 }} />
      </div>
      <div className={styles.ftrCol}>
        <div className={styles.ftrPhone}>
          <img src={callToRef} className={styles.ftrIcon} alt="Call US" />
          <a
            href="tel:+380950540473"
            className={styles.ftrLink}
          >
            +38 095 054 0473
          </a>
        </div>
        <div className={styles.ftrEmail}>
          <img src={envelopeRef} className={styles.ftrIcon} alt="Mail FUIB" style={{ marginRight: 12 }} />
          <a
            href="mailto:official@valuetek.com.ua"
            className={styles.ftrLink}
          >
            official@valuetek.com.ua
          </a>
        </div>
        <div className={styles.ftrAddress}>
          <img src={bestCityInTheWorldRef} className={styles.ftrIcon} alt="Best City in the World - Kyiv" />
          <a
            href={illynskaStreetMap}
            target="_blank"
            rel="noreferrer"
            className={styles.ftrLink}
          >
            Ukraine, Kyiv, 8 Illinska St.
          </a>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Footer)
